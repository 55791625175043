<script setup lang="ts"></script>

<template>
    <svg
        width="38"
        height="38"
        viewBox="0 0 38 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_273_904)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M37.9626 13.7613C38.2436 15.3604 36.9015 16.6863 35.2778 16.6863H22.7836C21.9717 16.6863 21.3137 16.0282 21.3137 15.2164V2.72221C21.3137 1.09859 22.6395 -0.243552 24.2386 0.0373918C25.4512 0.250425 26.6397 0.595765 27.7826 1.06913C29.8334 1.91864 31.697 3.16377 33.2665 4.73344C34.8362 6.30312 36.0814 8.16659 36.9309 10.2175C37.4042 11.3602 37.7496 12.5488 37.9626 13.7613ZM35.0333 23.2873C35.3028 21.6863 33.9616 20.361 32.338 20.361H19.05C18.2616 20.361 17.6135 19.739 17.5813 18.9511L17.0392 5.67426C16.973 4.052 15.5943 2.76604 14.0054 3.10049C11.6064 3.60553 9.32791 4.60755 7.32192 6.05409C4.51712 8.07668 2.36869 10.8791 1.14375 14.1129C-0.08118 17.3466 -0.32858 20.869 0.432318 24.2423C1.19322 27.6156 2.92884 30.6908 5.42339 33.0856C7.91794 35.4804 11.0614 37.089 14.4629 37.7117C17.8644 38.3343 21.3738 37.9435 24.555 36.5876C27.736 35.2318 30.4484 32.9708 32.3549 30.0858C33.7184 28.0225 34.6266 25.705 35.0333 23.2873Z"
                fill="url(#paint0_linear_273_904)"
            />
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_273_904"
                x1="19"
                y1="0"
                x2="19"
                y2="38"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#A2BADC" />
                <stop offset="1" stop-color="#68799C" />
            </linearGradient>
            <clipPath id="clip0_273_904">
                <rect width="38" height="38" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
